import {
    AfterViewInit,
    Directive, ElementRef,
    Host,
    HostBinding,
    HostListener,
    Input, OnDestroy,
    OnInit,
    Optional,
    Renderer2,
    Self,
    ViewChild
} from '@angular/core';
import {DomController, IonContent, IonHeader} from '@ionic/angular';
import {debounceTime, distinctUntilChanged, filter, map, pairwise, share, tap, throttleTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';

enum Direction {
    Up = 'Up',
    Down = 'Down'
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[scrollHeader]'
})
export class HideHeaderDirective implements AfterViewInit, OnDestroy {

    @Input() scrollContent: IonContent;

    @Input() triggerDistance: number = 40;

    private hidden: boolean;
    private scrollSub: Subscription;

    public get isHidden(): boolean {
        return this.hidden;
    }

    constructor(
        private hostElement: ElementRef,
        private renderer: Renderer2,
        private domCtrl: DomController
    ) {
    }

    ngAfterViewInit(): void {
        this.domCtrl.write(() => {
            this.renderer.setStyle(this.hostElement.nativeElement, 'transition', 'margin-top 700ms');
            this.renderer.setStyle(this.hostElement.nativeElement, 'transition', 'opacity 700ms');

        });

        if (this.scrollContent != null) {
            this.scrollContent.scrollEvents = true;
            this.bindScrollEvent();
        }
    }

    ngOnDestroy(): void {
        if (!!this.scrollSub) {
            this.scrollSub.unsubscribe();
            this.scrollSub = null;
        }
    }

    private bindScrollEvent() {
        this.scrollSub = this.scrollContent.ionScroll
            .pipe(debounceTime(10))
            .subscribe(event => {
            if (event.detail.currentY === 0 && this.hidden) {
                this.show();
            } else if (!this.hidden && event.detail.deltaY > this.triggerDistance) {
                this.hide();
            } else if (this.hidden && event.detail.deltaY < -this.triggerDistance) {
                this.show();
            }
        });

    }

    hide() {
        this.domCtrl.write(() => {
            this.renderer.setStyle(this.hostElement.nativeElement, 'margin-top', `-${this.hostElement.nativeElement.offsetHeight}px`);
            this.renderer.setStyle(this.hostElement.nativeElement, 'opacity', '0');
        });

        this.hidden = true;
    }

    show() {
        this.domCtrl.write(() => {
            this.renderer.removeStyle(this.hostElement.nativeElement, 'opacity');
            this.renderer.removeStyle(this.hostElement.nativeElement, 'margin-top');
        });

        this.hidden = false;
    }

}
