import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PrivacyPolicyPage} from './pages/privacy-policy/privacy-policy.page';
import {TermsOfServicePage} from './pages/terms-of-service/terms-of-service.page';

const routes: Routes = [
    {path: '', redirectTo: '/walkthrough', pathMatch: 'full'},
    {path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)},
    {path: 'walkthrough', loadChildren: () => import('./walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule)},
    {path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)},
    {path: 'signup', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)},
    {path: 'privacy', component: PrivacyPolicyPage},
    {path: 'terms-of-service', component: TermsOfServicePage},
    // tslint:disable-next-line:max-line-length
    {path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)},
    {path: 'app', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)},
    // tslint:disable-next-line:max-line-length
    {path: '404', loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)},
    {path: '**', redirectTo: '404'},


];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
