import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TermsOfServicePage} from './terms-of-service/terms-of-service.page';
import {PrivacyPolicyPage} from './privacy-policy/privacy-policy.page';
import {PageNotFoundModule} from './page-not-found/page-not-found.module';
import {SignupPageModule} from './signup/signup.module';
import {LoginPageModule} from './login/login.module';
import {ForgotPasswordPageModule} from './forgot-password/forgot-password.module';
import {IonicModule} from '@ionic/angular';
import {ComponentsModule} from '../components/components.module';
import {PrivacyPolicyModal} from './privacy-policy/modal/privacy-policy.modal';


@NgModule({
    declarations: [TermsOfServicePage, PrivacyPolicyPage, PrivacyPolicyModal],
    imports: [
        CommonModule,
        IonicModule,
        ComponentsModule,
        // Sub modules
        PageNotFoundModule,
        SignupPageModule,
        LoginPageModule,
        ForgotPasswordPageModule
    ],
    entryComponents: [TermsOfServicePage, PrivacyPolicyPage, PrivacyPolicyModal]
})
export class PagesModule {
}
